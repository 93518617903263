export const clinicAdapter =
    ({
       name,
       type,
       idcity,
       address,
       bin,
       phone,
       email,
       currency_id,
       tariff_id,
       paid_functionality,
       language,
       source,
       total_docs,
       quote_value_readable,
       time_zone,
       comment,
       manager_id,
       partner_id,
       lname,
       fname,
       mname,
       use_proxy,
       egisz_prod
     }) => ({
      name: name ? name : null,
      type,
      idcity: idcity ? idcity : null,
      address: address ? address : null,
      bin: bin ? bin : null,
      phone: phone ? phone : null,
      email: email ? email : null,
      currency_id: currency_id ? currency_id : null,
      tariff_id: tariff_id ? tariff_id : null,
      paid_functionality: paid_functionality ? (paid_functionality.length ? (paid_functionality || []).map(x => x.id) : '') : null,
      language: language ? language : null,
      source: source ? source : null,
      total_docs: total_docs ?? 2,
      quote_value_readable: quote_value_readable ?? 0,
      time_zone: time_zone ? time_zone : null,
      comment: comment ? comment : null,
      manager_id: manager_id || null,
      partner_id: partner_id || null,
      lname: lname || null,
      fname: fname || null,
      mname: mname || null,
      use_proxy: use_proxy === true ? 1 : 0,
      egisz_prod: egisz_prod === true ? 1 : 0,
    })

