<template>
  <div v-if="showInput">
    <ServiceMultiSelect :label="$t('paid_functionality')"
                        :dblclick="false"
                        model="doctor"
                        title="title"
                        :dbsearch="false"
                        :key="`pf-${key}`"
                        :prepopulatedOptions="possiblePaid"
                        v-model="object.paid_functionality"/>
  </div>
</template>

<script setup>
import ServiceMultiSelect from "@/components/form/ServiceMultiSelect.vue"
import {computed, nextTick, onMounted, ref, watch} from "vue"
import {SuperadminService} from "@/services/api.service"
import {useStore} from "vuex"

const props = defineProps({
  object: {}
})

const store = useStore()

const tariffs = ref([])
const key = ref(1)
const showInput = computed(() => store.state.auth.user?.role !== 'tech_specialist')

onMounted(async () => {
  await load()
  await nextTick()
  const ids = (props.object.clinic_tariff?.paid_functionality || []).map(x => Number(x))
  props.object.paid_functionality = possiblePaid.value.filter(obj => ids.includes(obj.id))
  key.value++
})

const load = async () => {
  tariffs.value = (await SuperadminService.allTariffs()).data
}

const possiblePaid = computed(() => {
  const tariff = tariffs.value.find(obj => Number(obj.id) === Number(props.object.tariff_id))
  return tariff?.paid_functions || []
})

watch(() => props.object.tariff_id, () => {
  props.object.paid_functionality = []
  key.value++
})
</script>
