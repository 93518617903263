import { billingPaymentAdapter } from "@/adapters/superadmin/billingPayment"
import moment from "moment"
import { billingPaymentForTypes } from "@/dictionaries/dictionary"

export default {
    primaryKey: "id",
    model: 'billing_payment',
    order: 'id',
    sort: 'desc',
    actions: [
        "add",
        "edit",
        "excel",
        "delete"
    ],
    rights: {
        add: "superadmin_payment_add",
        edit: "superadmin_payment_edit",
        delete: "superadmin_payment_delete",
    },
    showTotalRecords: true,
    actionscomponent: 'SuperAdminPaymentActions',
    deleteCondition(object, store) {
        if(store.state.auth.user?.role === 'account_manager') {
            const hoursPassed = moment().diff(moment(object.created_at), 'hours')
            if(hoursPassed > 24) return false
        }
        return object.paid == 0 || object.pay_system == 'manual'
    },
    adapter: billingPaymentAdapter,
    deletemessage: 'are_you_sure_you_want_to_delete_payment',
    fields: [
        {
            id: 'id',
            title: 'id',
            table: true,
            sortable: true
        },
        {
            id: 'clinic_id',
            title: 'clinic',
            table_show: ['clinic_id', 'clinic.name'],
            table: true,
            sortable: true,
            filter: true,
            form: {
                type: "autocomplete",
                model: "clinic",
                required: true,
            },
        },
        {
            id: 'manager_id',
            title: 'manager2',
            table_show: ['manager_id', 'manager.fname'],
            table: true,
            filter: true,
            filter_model_select: true,
            sortable: false
        },
        {
            id: 'partner_id',
            title: 'partner',
            table_show: ['partner_id', 'partner.fname'],
            table: true,
            filter: true,
            filter_model_select: true,
            sortable: false
        },
        {
            id: 'account_manager_id',
            title: 'account_manager',
            table_show: ['account_manager_id', 'account_manager.fname'],
            table: true,
            filter: true,
            filter_model_select: true,
            sortable: false
        },
        {
            id: 'tariff_id',
            title: 'tariff',
            table_show: ['tariff.title'],
            table: true,
            filter: true,
            filter_model_select: true,
            sortable: true
        },
        {
            id: 'currency',
            title: 'currency',
            table: true,
            sortable: true,
            format_translate: false,
            filter: true,
            filter_list_select: true,
            form: {
                component: "BillingPaymentFormCard"
            }
        },
        {
            id: 'sum',
            title: 'sum',
            table: true,
            sortable: true,
            filter: true,
            filter_number: true,
            // form: {
            //     classes: 'col-md-6',
            //     type: "number",
            //     required: true,
            // },
        },
        {
            id: 'discount_percent',
            title: 'discount',
            table: true,
            sortable: true,
            filter: true,
            filter_number: true,
            // form: {
            //     classes: 'col-md-6',
            //     type: "number",
            //     required: true,
            // },
        },
        {
            id: 'pay_sum_kzt_whole',
            title: 'Оплата в тенге',
            table: true,
            sortable: true,
            format_number: true,
            filter: true,
            filter_number: true,
        },
        {
            id: 'pay_system',
            title: 'pay_system',
            table: true,
            sortable: true,
            filter: true,
            format_translate: true,
            filter_list_select: true,
            form: {
                options: {
                    "manual": { id: "manual", title: "manual" },
                    "ioka": { id: "ioka", title: "ioka" },
                    "robokassa": { id: "robokassa", title: "robokassa" },
                }
            }
        },
        {
            id: 'pay_system_id',
            title: 'manual_pay_system',
            table: true,
            sortable: true,
            filter: true,
            format_translate: true,
            filter_model_select: true,
            table_show: 'billing_pay_system.title',
            form: {
                type: "select",
                select_type: "model",
                select_data: {
                    "title": "title"
                },
                required: true,
                //     type: "select",
                //     select_type: "list",
                //     options: {
                //         "manual": { id: "manual", title: "manual" },
                //         "ioka": { id: "ioka", title: "ioka" },
                //     }
            }
        },
        {
            id: 'paid_for',
            title: 'Оплата за',
            table: true,
            tdcomponent: "SuperAdminDatatableTDPaymentForWhat",
            filter: true,
            filter_list_select: true,
            form: {
                options: billingPaymentForTypes
            }
        },
        {
            id: 'paid',
            title: 'paid',
            table: true,
            sortable: true,
            filter: true,
            filter_list_select: true,
            tdcomponent: "SuperAdminDatatableTDPaymentStatus",
            form: {
                type: 'select',
                options: {
                    "0": { id: "0", title: "unpaid" },
                    "1": { id: "1", title: "paid" },
                }
            }
        },
        {
            id: 'comment',
            title: 'payment_comment',
            table: true,
            filter: true,
            form: {
                type: "textarea",
            }
        },
        {
            id: 'original',
            title: 'Квитанция об оплате',
            table: true,
            sortable: true,
            form: {
                type: "file",
                filetype: "graph_document",
                sizelimit: 10,
                multiple: false,
                required: false
            },
            tdcomponent: "SuperAdminDatatableTDPaymentAttachment"
        },
        {
            id: 'author',
            title: 'author',
            table: true,
            table_show: 'author.full_name',
        },
        {
            id: 'created_at',
            title: 'date',
            table: true,
            sortable: true,
            format_datetime: true,
            filter: true
        },
        {
            id: 'partner_id',
            title: 'partner',
            table: false,
            form: {
                type: "select",
                select_type: "model",
                select_data: {
                    "title": "title"
                },
                classes: 'col-md-6',
            }
        },
        {
            id: 'account_manager_id',
            title: 'account_manager',
            table: false,
            form: {
                type: "select",
                select_type: "model",
                select_data: {
                    "title": "title"
                },
                required: true,
                classes: 'col-md-6',
            }
        },
        {
            id: 'manager_id',
            title: 'manager2',
            table: false,
            form: {
                type: "select",
                readonly: true,
                select_type: "model",
                select_data: {
                    "title": "title"
                },
                classes: 'col-md-12',
            }
        },
        {
            id: 'confirmed',
            title: 'confirmed',
            table: true,
            sortable: true,
            form: {
                type: "checkbox",
                conditionFunc(store) {
                    return store.state.auth.user?.role !== 'account_manager'
                },
            },
            tdcomponent: 'CRMDatatableTDYesNoBoolean',
        },
        {
            id: 'upselling',
            title: 'upselling',
            table: true,
            sortable: true,
            form: {
                type: "checkbox"
            },
            tdcomponent: 'CRMDatatableTDYesNoBoolean',
        },
        {
            id: 'date_from',
            title: 'date_from',
            filter: true,
            filter_date: true,
        },
        {
            id: 'date_to',
            title: 'date_to',
            filter: true,
            filter_date: true,
        },
        {
            id: 'is_initial_payment',
            title: 'first_payment',
            filter: true,
            filter_boolean: true,
        },
    ]
}
